import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Qrlcn from "@/views/Qrlcn.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Qrlcn,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
